import React from 'react';

import CategoryItem from '../directory-item/directory-item.component';

import './directory.styles.scss';

const categories = [
  {
    id: 1,
    title: 'Hats',
    imageUrl: require('../../img/img-1.jpeg'),
    route: 'shop/hats',
  },
  {
    id: 2,
    title: 'Jackets',
    imageUrl: require('../../img/img-2.jpeg'),
    route: 'shop/jackets',
  },
  {
    id: 3,
    title: 'Sneakers',
    imageUrl: require('../../img/img-3.jpeg'),
    route: 'shop/sneakers',
  },
  {
    id: 4,
    title: "Men's",
    imageUrl: require('../../img/img-5.jpeg'),
    route: 'shop/mens',
  },
  {
    id: 5,
    title: "Women's",
    imageUrl: require('../../img/img-4.jpeg'),
    route: 'shop/womens',
  },
];

const Directory = () => {
  return (
    <div className='directory-container'>
      {categories.map((category) => (
        <CategoryItem key={category.id} category={category} />
      ))}
    </div>
  );
};

export default Directory;
