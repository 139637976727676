import React from 'react';

import HeaderVideo from '../../components/video-header/video-header.component';
import Directory from '../../components/directory/directory.component';

const Home = () => {
  return (
    <>
      <HeaderVideo />
      <Directory />
    </>
  );
};

export default Home;
