import React from 'react';

import './video-header.styles.scss';

const VideoHeader = () => {
  return (
    <div>
      <h1 className='text'> Sit Down Please Collection</h1>
      <video className='video' autoPlay muted loop>
        <source
          src='http://thenewcode.com/assets/videos/fashion.mp4'
          type='video/mp4'
        />
      </video>
    </div>
  );
};

export default VideoHeader;
